<template>
  <div class="">
    <FAQsForm ref="ChatForm" v-model="formData" @formSubmitted="storeChat"/>
  </div>
</template>

<script>
import FAQsForm from "@/views/components/FAQs/FAQsForm";
import FAQsService from "@/services/FAQsService";

export default {
  name: "CreateChat",
  components: {
    FAQsForm
  },
  data() {
    return {
      fAQsService: new FAQsService(),
      formData: {
        // what key i have
      },
    }
  },
  methods: {
    storeChat(e) {
      this.fAQsService.create(e).then(res => {
        this.$router.go(-1)
      }).catch(err => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>
</style>
